import Auth from "./modules/Auth";
import Campaigns from "./modules/Campaigns";
import ChannelType from "./modules/ChannelType";
import Payment from "./modules/Payment";
import CampaignStatsSmsList from "./modules/CampaignStatsSmsList";
import CampaignStatsMailingList from "./modules/CampaignStatsMailingList";
import CampaignStatsVoiceList from "./modules/CampaignStatsVoiceList";
import CampaignStats from "./modules/CampaignStats";
import Support from "./modules/Support";
import Profile from "./modules/Profile";
import Balance from "./modules/Balance";

import { createStore } from "vuex";

const store = createStore({
  modules: {
    auth: Auth,
    campaigns: Campaigns,
    channelType: ChannelType,
    payment: Payment,
    campaignStatsSmsList: CampaignStatsSmsList,
    campaignStatsMailingList: CampaignStatsMailingList,
    campaignStatsVoiceList: CampaignStatsVoiceList,
    campaignStats: CampaignStats,
    support: Support,
    profile: Profile,
    balance: Balance,
  },
});

export default store;
