<template>
  <ion-page>
    <app-header page-title="Soporte" />
    <ion-content class="bg-default">
      <form>
        <ion-grid>
          <ion-row>
            <ion-col>
              <p class="ion-text-center">{{ $t('Deja tu mensaje') }}.</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">{{ $t('Asunto') }}:</ion-label>
                <ion-input
                  name="subject"
                  type="text"
                  :value="support.subject"
                  @ionInput="support.subject = $event.target.value"
                ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">{{ $t('Mensaje') }}:</ion-label>
                <ion-textarea
                  name="message"
                  type="text"
                  :value="support.message"
                  rows="6"
                  cols="20"
                  @ionInput="support.message = $event.target.value"
                ></ion-textarea>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-button
            name="btnLogin"
            type="button"
            class="ion-margin-top ion-margin-bottom"
            expand="block"
            @click="sendMessage"
            >{{ $t('Acceder') }}</ion-button
          >
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
} from "@ionic/vue";
import AppHeader from "../../components/Header.vue";

export default defineComponent({
  name: "SupportPage",
  components: {
    IonPage,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonTextarea,
    AppHeader,
  },
  computed: {
    support() {
      return this.$store.state.support;
    },
  },
  methods: {
    async sendMessage() {
      this.loadingPresenter();
      const data = {
        subject: this.support.subject,
        message: this.support.message,
      };

      try {
        await this.$store.dispatch("support/sendMessage", data);
        this.successAlertNotification("El mensaje se envió correctamente.");
      } catch (error) {
        this.errorAlertNotification("Error al contactar a soporte.");
      } finally {
        this.loadingDismiss();
      }
    },
  },
});
</script>

<style scoped>
</style>