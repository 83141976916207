import { loadingController } from "@ionic/vue";

export default {
  install: (app) => {
    app.config.globalProperties.loadingPresenter = async () => {
      const loading = await loadingController.create({
        cssClass: "loading",
        message: "Cargando...",
      });

      await loading.present();
    };
    app.config.globalProperties.loadingDismiss = async () => {
      await loadingController.dismiss();
    };
  },
};
