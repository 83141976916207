const state = () => ({
  SMS_ID: 1,
  MAILING_ID: 2,
  LANDING_PAGE_ID: 3,
  VOICE_ID: 4,
  PUSH_APP_ID: 5,
  PUSH_WEB_ID: 6,
  VOICE_INTERACTIVE_ID: 7,
  SMS: "sms",
  MAILING: "mailing",
  LANDING_PAGE: "landing",
  VOICE_INTERACTIVE: "voice-interactive",
});

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
