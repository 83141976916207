<template>
  <ion-modal :is-open="modal">
    <ion-header translucent>
      <ion-toolbar color="primary">
        <ion-title>{{ $t('Filtrar') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="toggleModal()"
            ><ion-icon :icon="closeOutline" size="large"></ion-icon>
          </ion-button>
           <ion-button  @click="apply()" :strong="true">
              <ion-icon :icon="checkmark" size="large"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content fullscreen class="bg-default">
      <ion-card>
        <ion-grid>
          <ion-row>
            <ion-col v-if="localInitDate != ''">
              <p>{{ $t('Fecha desde') }}</p>
              <div>
                <ion-datetime
                  presentation="date"
                  :value="localInitDate"
                  locale="es-ES"
                  @ionChange="localInitDate = $event.target.value"
                ></ion-datetime>
              </div>
            </ion-col>
            <ion-col v-if="localEndDate != ''">
              <p>{{ $t('Fecha hasta') }}</p>
              <div>
                <ion-datetime
                  presentation="date"
                  :value="localEndDate"
                  locale="es-ES"
                  @ionChange="localEndDate = $event.target.value"
                ></ion-datetime>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
    </ion-content>
  </ion-modal>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonButton,
  IonModal,
  IonDatetime,
} from "@ionic/vue";
import { filterOutline, closeOutline, checkmark } from "ionicons/icons";

export default defineComponent({
  name: "FilterList",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonButton,
    IonModal,
    IonDatetime,
  },
  props: {
    initDate: {
      require: false,
      type: String,
      default: "",
    },
    endDate: {
      require: false,
      type: String,
      default: "",
    },
    campaignStatus: {
      require: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterOutline,
      closeOutline,
      checkmark,
      modal: false,
      localInitDate: this.initDate,
      localEndDate: this.endDate,
      localCampaignStatus: this.campaignStatus,
    };
  },

  methods: {
    async toggleModal() {
      this.modal = !this.modal;
    },
    apply() {
      this.$emit("filterApply", {
        initDate: this.localInitDate,
        endDate: this.localEndDate,
      });

      this.toggleModal();
    },
  },
  created () {
    this.emitter.on('showFilters', () => { 
      this.toggleModal()
    })
  }
});
</script>

<style scoped>
.icon-filter {
  margin-right: 5px;
}
</style>
