<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>{{ $t("Añadir saldo") }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="bg-default">
      <ion-card>
        <ion-card-content>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">{{ $t("") }}Importe:</ion-label>
                <ion-input
                  name="amount"
                  type="number"
                  :value="payment.amount"
                  @ionInput="payment.amount = $event.target.value"
                ></ion-input>
              </ion-item>
              <ion-label class="ion-padding">
                {{ $t("Importe mínimo") }}
                {{ currencyFormat(payment.paymentDetail.minAmountLocal) }}
                {{ currencySymbol(payment.currency.code) }}.
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-button
            :disabled="!payment.amount"
            color="success"
            type="button"
            class="ion-margin-top ion-margin-bottom"
            expand="block"
            @click="confirm()"
            >{{ $t("Continuar") }}</ion-button
          >
          <payment-add-bank-trans-info />
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonTitle,
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
} from "@ionic/vue";
import PaymentAddBankTransInfo from "./PaymentAddBankTransferInfo.vue";

export default defineComponent({
  name: "PaymentAddBankTransferSelected",
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonBackButton,
    IonTitle,
    IonCard,
    IonCardContent,
    IonRow,
    IonCol,
    IonItem,
    IonInput,
    IonButton,
    PaymentAddBankTransInfo,
  },
  computed: {
    payment() {
      return this.$store.state.payment;
    },
  },
  methods: {
    async confirm() {
      this.loadingPresenter();

      const data = {
        amount: this.parseAmount(this.payment.amount),
        infobillingId:
          this.payment.paymentDetail.infoBilling.billingProfiles[0].id,
      };

      try {
        await this.$store.dispatch("payment/confirmBankPaymentMethod", data);
        this.$router.push({ name: "PaymentAddBankTransferConfirm" });
      } catch (error) {
        this.errorAlertNotification(
          `${error.response.data.message} ${error.response.data.minAmount}` ||
            "Error al obtener los datos de transferencia bancaria"
        );
      } finally {
        this.loadingDismiss();
      }
    },
  },
});
</script>

<style scoped></style>
