import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import middleware from "@grafikri/vue-middleware";
import errorAlertNotification from "./plugins/AlertNotification";
import successAlertNotification from "./plugins/AlertNotification";
import loadingPresenter from "./plugins/Loading";
import loadingDismiss from "./plugins/Loading";
import currencyFormat from "./plugins/CurrencyFormat";
import currencySymbol from "./plugins/CurrencyFormat";
import parseAmount from "./plugins/CurrencyFormat";
import dateFormat from "./plugins/Date";
import mitt from 'mitt'
const emitter = mitt();

import { IonicVue } from "@ionic/vue";
import { createI18n } from 'vue-i18n'
import { messages } from './locales/messages'


/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Theme main */
import "./theme/main.css";

import "./registerServiceWorker";
import './registerServiceWorker'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, faUniversity, faCreditCard } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret, faUniversity, faCreditCard)

localStorage.setItem('language', 'es')
const i18n = createI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages, // set locale messages
})

const app = createApp(App)
  .use(IonicVue)
  .use(i18n)
  .use(router)
  .use(store)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(errorAlertNotification, successAlertNotification)
  .use(loadingPresenter, loadingDismiss)
  .use(currencyFormat, currencySymbol, parseAmount)
  .use(dateFormat)

  app.config.globalProperties.emitter = emitter;
  
  app.config.globalProperties.$t = function (...args) {
    const result = this.$i18n.t.apply(this.$i18n, args)
    if (result === '') {
      return this.$i18n.t(args[0], this.$i18n.fallbackLocale, args[2])
    }
    return result
  }
  
  app.config.globalProperties.$tc = function (...args) {
    const result = this.$i18n.tc.apply(this.$i18n, args)
    if (result === '') {
      return this.$i18n.t(args[0], args[1], this.$i18n.fallbackLocale, args[3])
    }
    return result
  }
  
router.beforeEach(middleware());
router.isReady().then(() => {
  app.mount("#app");
});
