import Service from "./Service";

const PATH = "/campaign";

class CampaignService extends Service {
  getAll(params = {}) {
    return this.get(PATH + "/all", params);
  }
}

export default new CampaignService();