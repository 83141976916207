export class Languages {
  
  constructor() {
    this.defaultLanguageIso = ''
    this.languages = [
      {
        iso: 'es',
        locale: 'es-ES',
        tooltip: 'Español',
      },
      {
        iso: 'en',
        locale: 'en-GB',
        tooltip: 'English',
      },
      {
        iso: 'it',
        locale: 'it-IT',
        tooltip: 'Italiano',
      },
      {
        iso: 'pt',
        locale: 'pt-PT',
        tooltip: 'Português',
      },
      {
        iso: 'fr',
        locale: 'fr-FR',
        tooltip: 'Français',
      },
      {
        iso: 'ro',
        locale: 'ro-RO',
        tooltip: 'Română',
      },
    ]
  }

}
