import CampaignStatsService from "../../services/CampaignStatsService";
import moment from "moment";

const state = () => ({
  all: [],
  perPage: 5,
  total: 0,
  currentPage: 0,
  lastPage: 0,
  initDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
});

const getters = {};

const actions = {
  async getChannelDataTable({ commit }, data) {
    try {
      const response = await CampaignStatsService.getSmsChannelDataTable(data);
      commit("setAll", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getChannelDataTableFilter({ commit }, data) {
    try {
      const response = await CampaignStatsService.getSmsChannelDataTable(data);
      response.initDate = data.initDate;
      response.endDate = data.endDate;
      commit("setAllFilter", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setAll(state, response) {
    if (response.data.length > 0) {
      for (let data of response.data) {
        state.all.push(data);
      }
    }
    state.perPage = response.per_page;
    state.total = response.total;
    state.currentPage = response.current_page;
    state.lastPage = response.last_page;
  },
  setAllFilter(state, response) {
    state.all = response.data;
    state.perPage = response.per_page;
    state.total = response.total;
    state.currentPage = response.current_page;
    state.lastPage = response.last_page;
    state.initDate = response.initDate;
    state.endDate = response.endDate;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
