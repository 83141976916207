import PaymentService from "../../services/PaymentService";

const state = () => ({
  activeCreditCard: false,
  activeMultibanco: false,
  activePaypal: false,
  activePlan: false,
  activeSubscription: null,
  activeTransfer: null,
  amount: "",
  bankTransferInfo: null,
  billingProfiles: null,
  currency: null,
  complete: false,
  defaultCreditCardId: "0",
  deletedCreditCardId: 0,
  entity: null,
  fromPlans: false,
  invoice: false,
  invoiceTmp: false,
  infoBillingIsoCode: false,
  paymentsInfo: null,
  paymentMethod: { bankTransfer: 1, multiBanco: 2, creditCard: 3, paypal: 4 },
  paymentDetail: null,
  paymentMethodSelectedId: false,
  reference: null,
  step: 1,
  show: false,
  showInvoice: false,
  showUserProfile: false,
  showCreditCardDelete: false,
});

const getters = {};

const actions = {
  async getPaymentMethod({ commit }) {
    try {
      const response = await PaymentService.getPaymentMethod();
      commit("setPaymentMethod", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getBankPaymentMethod({ commit }) {
    try {
      const response = await PaymentService.getBankPaymentMethod();
      commit("setBankPaymentMethod", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getCreditCardPaymentMethod({ commit }) {
    try {
      const response = await PaymentService.getCreditCardPaymentMethod();
      commit("setCreditCardPaymentMethod", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async confirmBankPaymentMethod({ commit }, data) {
    try {
      const response = await PaymentService.confirmBankPaymentMethod(data);
      commit("setConfirmBankPaymentMethod", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async confirmCreditCardPaymentMethod({ commit }, data) {
    try {
      const response = await PaymentService.confirmCreditCardPaymentMethod(
        data
      );
      commit("setConfirmCreditCardPaymentMethod", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async callbackBankPaymentMethod({ commit }, data) {
    try {
      const response = await PaymentService.callbackBankPaymentMethod(data);
      commit("setCallbackBankPaymentMethod", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async callbackCreditCardPaymentMethod({ commit }, data) {
    try {
      const response = await PaymentService.callbackCreditCardPaymentMethod(
        data
      );
      commit("setCallbackCreditCardPaymentMethod", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setPaymentMethod(state, response) {
    state.activeCreditCard = response.activeCreditCard;
    state.activeMultibanco = response.activeMultibanco;
    state.activeTransfer = response.activeTransfer;
    state.activePaypal = response.activePaypal;
    state.activePlan = response.activePlan;
    state.activeSubscription = response.activeSubscription;
    state.billingProfiles = response.billingProfiles;
    state.currency = response.currency;
    state.fromPlans = response.fromPlans;
    state.paymentsInfo = response.paymentsInfo;
    state.step = 1;
    state.show = true;
  },
  setBankPaymentMethod(state, response) {
    state.paymentDetail = response;
    state.paymentMethodSelectedId = state.paymentMethod.bankTransfer;
    state.step = 2;
  },
  setCreditCardPaymentMethod(state, response) {
    state.paymentDetail = response;
    state.defaultCreditCardId =
      response.subscribedCards.length > 0
        ? response.subscribedCards[0].id
        : "0";
    state.step = 2;
  },
  setConfirmBankPaymentMethod(state, response) {
    state.invoiceTmp = response;
    state.step = 3;
  },
  setConfirmCreditCardPaymentMethod(state, response) {
    state.invoiceTmp = response.invoiceTmp;
    state.urlCreditCardPay = response.url;
    state.step = 3;
  },
  setCallbackBankPaymentMethod(state, response) {
    state.infoBillingIsoCode = response.infoBillingIsoCode;
    state.invoice = response.invoice;
    state.complete = true;
    state.step = 4;
  },
  setCallbackCreditCardPaymentMethod(state, response) {
    state.infoBillingIsoCode = response.infoBillingIsoCode;
    state.invoice = response.invoice;
    state.complete = true;
    state.step = 4;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
