import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";

const state = () => ({
  step: 1,
  username: "",
  password: "",
  twoWayCode: "",
  twoWayCodeLength: 6,
  loggedToken: false,
  rememberme: false,
  email: "",
  success: false,
  user: JSON.parse(localStorage.getItem("user")) ?? {},
  sectionsBlockeds: JSON.parse(localStorage.getItem("sectionsBlockeds")) ?? {},
  twoWayRememberToken: localStorage.getItem("twoWayRememberToken") ?? null,
});

const getters = {};

const actions = {
  async login({ commit }, data) {
    try {
      const response = await AuthService.login(data);
      if (response.token) {
        commit("setLoginAuth", response);
      } else {
        commit("setTwoWayAuth", response);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async twoWayAuth({ commit }, data) {
    try {
      const response = await AuthService.twoWayCheck(data);
      if (response.token) {
        commit("setLoginTwoWayAuth", response);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSectionsBlockeds({ commit }) {
    try {
      const response = await UserService.getSectionsBlocked();
      commit("setSectionsBlockeds", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async logout({ commit }) {
    try {
      const response = await AuthService.logout();
      commit("setLogout", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setTwoWayAuth(state, data) {
    state.step = 2;
    state.username = data.username;
    state.twoWayCodeLength = data.two_way_code_length;
    state.loggedToken = data.logged_token;
    state.email = data.email;
  },
  setLoginAuth(state, data) {
    state.token = data.token;
    state.user = data;
    localStorage.setItem("user", JSON.stringify(data));
  },
  setLoginTwoWayAuth(state, data) {
    state.token = data.token;
    state.user = data;
    state.twoWayRememberToken = data.rememberToken;

    localStorage.setItem("twoWayRememberToken", data.rememberToken);
    localStorage.setItem("user", JSON.stringify(data));
  },
  setSectionsBlockeds(state, data) {
    const sections = [];
    for (const section of data) {
      sections.push(section.toLowerCase());
    }
    state.sectionsBlockeds = JSON.stringify(sections);
    localStorage.setItem("sectionsBlockeds", JSON.stringify(sections));
  },
  setLogout(state) {
    console.log("setLogout")
    state.step = 1;
    state.username = "";
    state.password = "";
    state.twoWayCode = "";
    state.twoWayCodeLength = 6;
    state.loggedToken = false;
    state.rememberme = false;
    state.email = "";
    state.twoWayRememberToken = null;
    state.token = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
