<template>
  <div class="content-info">
    <ul class="ul-info">
      <li>{{ $t('Carga de saldo inmediata') }}.</li>
      <li>{{ $t('Pago seguro') }}.</li>
      <li>{{ $t('Aceptamos Visa, Mastercard y American Express') }}.</li>
      <li>{{ $t('El pago debe ser recibido dentro de los 7 días') }}.</li>
      <li>
        <div
          v-if="
            payment.paymentsInfo.creditCard.commission > 0 &&
            payment.paymentsInfo.creditCard.fixed_commission > 0
          "
        >
        {{ $t('Se aplica una tasa de tramitación de') }}
          {{ payment.paymentsInfo.creditCard.commission }}% +
          {{ currencyFormat(payment.paymentsInfo.creditCard.fixed_commission) }}
          {{ currencySymbol(payment.currency.code) }}
        </div>
        <div v-else-if="payment.paymentsInfo.creditCard.commission > 0">
          {{ $t('Se aplica una tasa de tramitación de') }}
          {{ payment.paymentsInfo.creditCard.commission }}%
        </div>
        <div v-else-if="payment.paymentsInfo.creditCard.fixed_commission > 0">
          {{ $t('Se aplica una tasa de tramitación de') }}
          {{ currencyFormat(payment.paymentsInfo.creditCard.fixed_commission) }}
          {{ currencySymbol(payment.currency.code) }}
        </div>
        <div v-else>{{ $t('Sin cargo') }}.</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaymentAddCreditCardInfo",
  computed: {
    payment() {
      return this.$store.state.payment;
    },
  },
});
</script>

<style scoped>
.content-info {
  margin: 10px;
  font-size: 10px;
  color: #999;
}

.ul-info {
  padding-left: 15px;
}

.ul-info li {
  margin-bottom: 10px;
}
</style>