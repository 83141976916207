<template>
  <ion-items>
    <ion-item>
      {{ $t('Carga de saldo inmediata') }}
    </ion-item>
    <ion-item>
      {{ $t('Pago seguro') }}.
    </ion-item>
    <ion-item>
      {{ $t('Aceptamos Visa y Mastercard') }}.
    </ion-item>
    <ion-item>
      <div
        v-if="
          payment.paymentsInfo.creditCard.commission > 0 &&
          payment.paymentsInfo.creditCard.fixed_commission > 0
        "
      >
      {{ $t('Se aplica una tasa de tramitación de') }}
        {{ payment.paymentsInfo.creditCard.commission }}% +
        {{ currencyFormat(payment.paymentsInfo.creditCard.fixed_commission) }}
        {{ currencySymbol(payment.currency.code) }}
      </div>
      <div v-else-if="payment.paymentsInfo.creditCard.commission > 0">
        {{ $t('Se aplica una tasa de tramitación de') }}
        {{ payment.paymentsInfo.creditCard.commission }}%
      </div>
      <div v-else-if="payment.paymentsInfo.creditCard.fixed_commission > 0">
        {{ $t('Se aplica una tasa de tramitación de') }}
        {{ currencyFormat(payment.paymentsInfo.creditCard.fixed_commission) }}
        {{ currencySymbol(payment.currency.code) }}
      </div>
      <div v-else>{{ $t('Sin cargo') }}.</div>
    </ion-item>
  <ion-item>
    <ion-grid>
      <ion-row>
        <ion-col size="4">
          <a
            href="https://www.360nrs.com/ventajas/certificado-iso-27001"
            target="_blank"
          >
          <ion-img :src="require('../../../public/assets/img/payments/certificado-iso.png')" :alt="$t('Certificado ISO')"></ion-img>
        </a>
      </ion-col>
        <ion-col size="4">
          <a
            href="https://www.confianzaonline.es/empresas/nrs.htm"
            target="_blank"
          >
            <ion-img :src="require('../../../public/assets/img/payments/ecommerce-confianza.png')" :alt="$t('Ecommerce de confianza')"></ion-img>
          </a>
        </ion-col>
        <ion-col size="4">
          <a
            href="https://sede.micinn.gob.es/pyiINFO/findPyi.mec?nif=B12550877"
              target="_blank"
            >
            <ion-img :src="require('../../../public/assets/img/payments/pyme-innovadora.png')" :alt="$t('Pyme innovadora')"></ion-img>
              
          </a>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="4" offset="4" >
          <ion-img
            v-if="payment.infoBillingIsoCode === 'CO'" 
                :src="require('../../../public/assets/img/payments/crc.png')"
                alt="CNMC"
              >
            </ion-img>
            <ion-img
              v-else 
                :src="require('../../../public/assets/img/payments/cnmc.png')"
                alt="CNMC"
              >
            </ion-img>
        </ion-col>
      </ion-row>
    </ion-grid>
    </ion-item>
  </ion-items>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaymentAddBankTransInfo",
  computed: {
    payment() {
      return this.$store.state.payment;
    },
  },
});
</script>

<style scoped>
.content-info {
  margin: 10px;
  font-size: 10px;
  color: #999;
}

.ul-info {
  padding-left: 15px;
}

.ul-info li {
  margin-bottom: 10px;
}
</style>