import Service from "./Service";

const PATH = "/payment";

class PaymentService extends Service {
  getPaymentMethod(params = {}) {
    return this.get(PATH, params);
  }
  getBankPaymentMethod(params = {}) {
    return this.get(PATH + "/bank", params);
  }
  confirmBankPaymentMethod(params = {}) {
    return this.post(PATH + "/bank/confirm", params);
  }
  callbackBankPaymentMethod(params = {}) {
    return this.post(PATH + "/bank/callback", params);
  }
  getCreditCardPaymentMethod(params = {}) {
    return this.get(PATH + "/credit-card", params);
  }
  confirmCreditCardPaymentMethod(params = {}) {
    return this.post(PATH + "/credit-card/confirm", params);
  }
  callbackCreditCardPaymentMethod(params = {}) {
    return this.post(PATH + "/credit-card/callback", params);
  }
}

export default new PaymentService();
