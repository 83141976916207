import Service from "./Service";

const PATH = "/stats";

class CampaignStatsService extends Service {
  getSms(campaignId) {
    return this.get(PATH + "/sms/campaign/" + campaignId);
  }
  getSmsDataTable(params = {}) {
    return this.get(PATH + "/sms/campaign-datatable", params);
  }
  getSmsChannelDataTable(params = {}) {
    return this.get(PATH + "/sms/channel/datatable", params);
  }
  getMailing(campaignId) {
    return this.get(PATH + "/mailing/campaign/" + campaignId);
  }
  getMailingDataTable(params = {}) {
    return this.get(PATH + "/mailing/campaign-datatable", params);
  }
  getMailingChannelDataTable(params = {}) {
    return this.get(PATH + "/mailing/channel/datatable", params);
  }
  getVoice(campaignId) {
    return this.get(PATH + "/voice/campaign/" + campaignId);
  }
  getVoiceDataTable(params = {}) {
    return this.get(PATH + "/voice/campaign-datatable", params);
  }
  getVoiceChannelDataTable(params = {}) {
    return this.get(PATH + "/voice/channel/datatable", params);
  }
}

export default new CampaignStatsService();
