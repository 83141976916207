import Service from "./Service";

const PATH = "/user";

class UserService extends Service {
  getSectionsBlocked(params = {}) {
    return this.get(PATH + "/sections-blocked", params);
  }
}

export default new UserService();
