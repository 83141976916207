import { createRouter, createWebHistory } from "@ionic/vue-router";
import LoginPage from "../views/auth/Login.vue";
import HomePage from "../views/home/Home.vue";
import CampaignListPage from "../views/campaigns/CampaignList.vue";
import PaymentAddPage from "../views/payments/PaymentAdd.vue";
import PaymentAddBankTransferSelectedPage from "../views/payments/PaymentAddBankTransferSelected.vue";
import PaymentAddBankTransferConfirmPage from "../views/payments/PaymentAddBankTransferConfirm.vue";
import PaymentAddBankTransferSuccessPage from "../views/payments/PaymentAddBankTransferSuccess.vue";
import PaymentAddCreditCardSelectedPage from "../views/payments/PaymentAddCreditCardSelected.vue";
import PaymentAddCreditCardConfirmPage from "../views/payments/PaymentAddCreditCardConfirm.vue";
import PaymentAddCreditCardSuccessPage from "../views/payments/PaymentAddCreditCardSuccess.vue";
import SmsCampaignStatsListPage from "../views/stats/campaigns/sms/SmsCampaignStatsList.vue";
import SmsCampaignStatsPage from "../views/stats/campaigns/sms/SmsCampaignStats.vue";
import MailingCampaignStatsListPage from "../views/stats/campaigns/mailing/MailingCampaignStatsList.vue";
import MailingCampaignStatsPage from "../views/stats/campaigns/mailing/MailingCampaignStats.vue";
import VoiceCampaignStatsListPage from "../views/stats/campaigns/voice/VoiceCampaignStatsList.vue";
import VoiceCampaignStatsPage from "../views/stats/campaigns/voice/VoiceCampaignStats.vue";
import SupportPage from "../views/support/Support.vue";
import ProfilePage from "../views/account/profile/Profile.vue";

import AuthMiddleware from "../middleware/Authentication.js";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/home",
    name: "Home",
    component: HomePage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    component: CampaignListPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/payments/add",
    name: "PaymentAdd",
    component: PaymentAddPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/payments/add/select/bank",
    name: "PaymentAddBankTransferSelected",
    component: PaymentAddBankTransferSelectedPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/payments/add/confirm/bank",
    name: "PaymentAddBankTransferConfirm",
    component: PaymentAddBankTransferConfirmPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/payments/add/success/bank",
    name: "PaymentAddBankTransferSuccess",
    component: PaymentAddBankTransferSuccessPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/payments/add/select/credit-card",
    name: "PaymentAddCreditCardSelected",
    component: PaymentAddCreditCardSelectedPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/payments/add/confirm/credit-card",
    name: "PaymentAddCreditCardConfirm",
    component: PaymentAddCreditCardConfirmPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/payments/add/success/credit-card",
    name: "PaymentAddCreditCardSuccess",
    component: PaymentAddCreditCardSuccessPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/stats/campaigns/sms",
    name: "SmsCampaignStatsList",
    component: SmsCampaignStatsListPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/stats/campaigns/:campaignId/sms",
    name: "SmsCampaignStats",
    component: SmsCampaignStatsPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/stats/campaigns/mailing",
    name: "MailingCampaignStatsList",
    component: MailingCampaignStatsListPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/stats/campaigns/:campaignId/mailing",
    name: "MailingCampaignStats",
    component: MailingCampaignStatsPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/stats/campaigns/voice",
    name: "VoiceCampaignStatsList",
    component: VoiceCampaignStatsListPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/stats/campaigns/:campaignId/voice",
    name: "VoiceCampaignStats",
    component: VoiceCampaignStatsPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/support",
    name: "Support",
    component: SupportPage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/account/profile",
    name: "Profile",
    component: ProfilePage,
    meta: {
      middleware: [AuthMiddleware],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
