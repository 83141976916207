import { alertController } from "@ionic/vue";

export default {
  install: (app) => {
    app.config.globalProperties.errorAlertNotification = async (message) => {
      const alert = await alertController.create({
        cssClass: "error-alert",
        header: "Error",
        message: message,
        buttons: ["Aceptar"],
      });
      await alert.present();
    };
    app.config.globalProperties.successAlertNotification = async (message) => {
      const alert = await alertController.create({
        cssClass: "success-alert",
        header: "Success",
        message: message,
        buttons: ["Aceptar"],
      });
      await alert.present();
    };
  },
};
