import CampaignService from "../../services/CampaignService";

const state = () => ({
  all: [],
  perPage: 5,
  total: 0,
  currentPage: 0,
  lastPage: 0,
  statuses: [],
});

const getters = {};

const actions = {
  async getAll({ commit }, data) {
    try {
      const response = await CampaignService.getAll(data);
      commit("setAll", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setAll(state, response) {
    for (let data of response.data.data) {
      state.all.push(data);
    }
    state.perPage = response.data.per_page;
    state.total = response.data.total;
    state.currentPage = response.data.current_page;
    state.lastPage = response.data.last_page;
    state.statuses = response.statuses;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
