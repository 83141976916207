<template>
  <ion-row>
    <ion-col>
      <p class="ion-text-center">
        {{ $t('Ingresa el código que has recibido por e-mail') }} 
        <br /> <br />
        {{ $t('de') }} {{ auth.email}}
      </p>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-item>
        <ion-label position="stacked">{{ $t('Código') }}</ion-label>
        <ion-input
          name="username"
          type="text"
          :value="auth.twoWayCode"
          @ionInput="auth.twoWayCode = $event.target.value"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-item>
        <ion-label>{{ $t('Recuérdame') }}</ion-label>
        <ion-checkbox
          @ionChange="changeRememberme"
          @change="changeRememberme"
          @click="changeRememberme"
        >
        </ion-checkbox>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-button
        name="btnLogin"
        type="button"
        class="ion-margin-top ion-margin-bottom"
        expand="block"
        @click="twoWayAuth"
        >{{ $t('Acceder') }}</ion-button
      >
    </ion-col>
  </ion-row>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCheckbox,
} from "@ionic/vue";

export default defineComponent({
  name: "LoginTwoFactor",
  components: {
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonCheckbox,
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },
  methods: {
    async twoWayAuth() {
      this.loadingPresenter();
      const data = {
        username: this.auth.username,
        rememberme: this.auth.rememberme,
        loggedToken: this.auth.loggedToken,
        twoWayCode: this.auth.twoWayCode,
      };

      try {
        await this.$store.dispatch("auth/twoWayAuth", data);

        if (this.auth.token) {
          const params = {
            locale: localStorage.getItem('language'),
          }
          await this.$store.dispatch("profile/updateLanguage", params);
          await this.$store.dispatch("auth/getSectionsBlockeds", data);
          this.$router.push({ name: "Home" });
        }
      } catch (error) {
        this.errorAlertNotification(error.response.data.message);
      } finally {
        console.log("auth terminado");
        this.loadingDismiss();
      }
    },
    changeRememberme() {
      this.auth.rememberme = !this.auth.rememberme;
    },
  },
});
</script>

<style scoped>
</style>