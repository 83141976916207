<template>
  <ion-page>
    <ion-content class="bg-default">
      <div id="container">
        <form>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-img
                  class="logo"
                  :src="require('../../../public/assets/logos/logo.svg')"
                  alt="Logo"
                ></ion-img>
              </ion-col>
            </ion-row>
            <login-form v-if="auth.step === 1" />
            <login-two-factor v-if="auth.step === 2" />
          </ion-grid>
        </form>
        <ion-grid>
            <ion-row>
              <ion-col style="text-align: center">
                <span
                  v-for="language in languagesObj.languages"
                  :key="language.iso"
                >
                  <img
                    :id="'btn-language-' + language.iso"
                    :src="setIsoFlag(language.iso)"
                    style="margin-left: 2px; margin-right: 2px;"
                    class="flag"
                    @click="changeLanguage(language.iso)"
                  >
                </span>
              </ion-col>
            </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/vue";
import LoginForm from "./LoginForm.vue";
import LoginTwoFactor from "./LoginTwoFactor.vue";
import { Languages } from '../../classes/Languages.js'

export default defineComponent({
  name: "LoginPage",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    LoginForm,
    LoginTwoFactor,
  },
  data() {
    return {
      languagesObj: new Languages(),
    };
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },
  methods: {
    setIsoFlag (isoCode) {
      const lang = isoCode === 'en' ? 'gb' : isoCode
      return require('../../../public/assets/flags/' + lang + '.svg')
    },
    changeLanguage (langIsoCode) {
      this.$i18n.locale = langIsoCode
      localStorage.setItem('language', langIsoCode)
    }
  }
});
</script>

<style scoped>
#container {
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
  transform: translateY(-30%);
}

.logo {
  width: 100%;
  max-width: 210px;
  display: block;
  margin: 20px auto;
}

.flag {
  width: 25px;
  height: 25px;
}
</style>