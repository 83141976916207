import es from './es.json'
import en from './en.json'
import ro from './ro.json'
import fr from './fr.json'
import it from './it.json'
import pt from './pt.json'

export const messages = {
  es,
  en,
  ro,
  fr,
  it,
  pt,
}
