<template>
  <ion-page>
    <app-header page-title="Soporte" />
    <ion-content class="bg-default">
      <div v-if="profile.company">
        <form>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">{{ $t('Username') }}:</ion-label>
                  <ion-input
                    name="username"
                    type="text"
                    :value="profile.user.username"
                    @ionInput="profile.user.username = $event.target.value"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">{{ $t('Compañía:') }}</ion-label>
                  <ion-input
                    name="company"
                    type="text"
                    :value="profile.company.name"
                    @ionInput="profile.company.name = $event.target.value"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">{{ $t('Dirección') }}:</ion-label>
                  <ion-textarea
                    name="company"
                    :value="profile.company.address"
                    @ionInput="profile.company.address = $event.target.value"
                  ></ion-textarea>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">{{ $t('Ciudad') }}:</ion-label>
                  <ion-input
                    name="city"
                    type="text"
                    :value="profile.company.city"
                    @ionInput="profile.company.city = $event.target.value"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">{{ $t('Código postal') }}:</ion-label>
                  <ion-input
                    name="postalCode"
                    type="text"
                    :value="profile.company.CP"
                    @ionInput="profile.company.CP = $event.target.value"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">{{ $t('País') }}:</ion-label>
                  <ion-select
                    placeholder="Seleccione un país"
                    :value="profile.company.country_id"
                    @ionInput="profile.company.country_id = $event.target.value"
                  >
                    <ion-select-option
                      v-for="(country, index) in profile.countriesMccList"
                      :key="index"
                      :value="country.value"
                      >{{ country.text }}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">{{ $t('Teléfono') }}:</ion-label>
                  <ion-input
                    name="postalCode"
                    type="number"
                    :value="profile.company.phonenumber"
                    @ionInput="
                      profile.company.phonenumber = $event.target.value
                    "
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">{{ $t('Zona horaria') }}:</ion-label>
                  <ion-select
                    placeholder="Seleccione zona horaria"
                    :value="profile.activeTimezone"
                    @ionInput="profile.activeTimezone = $event.target.value"
                  >
                    <ion-select-option
                      v-for="(timeZone, index) in profile.timezoneOffsets"
                      :key="index"
                      :value="timeZone.value"
                      >{{ timeZone.text }}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <p>
                  {{ $t('Esta es tu API Key que te permite usar la API de 360NRS') }}
                </p>
                <p><b>APIKEY:</b> {{ profile.apiKey }}</p>
              </ion-col>
            </ion-row>
            <ion-button
              name="btnLogin"
              type="button"
              class="ion-margin-top ion-margin-bottom"
              expand="block"
              @click="updateProfile()"
              >{{ $t('Actualizar') }}</ion-button
            >
          </ion-grid>
        </form>
      </div>
      <div v-else>
        <ion-list>
          <ion-item v-for="(item, index) in 10" :key="index">
            <ion-label>
              <h3>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </h3>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import AppHeader from "../../../components/Header.vue";

export default defineComponent({
  name: "ProfilePage",
  components: {
    IonPage,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    AppHeader,
  },
  computed: {
    profile() {
      return this.$store.state.profile;
    },
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    async getProfile() {
      try {
        await this.$store.dispatch("profile/getProfile");
      } catch (error) {
        this.errorAlertNotification(
          "Error al actualizar el perfil del usuario."
        );
      }
    },
    async updateProfile() {
      this.loadingPresenter();

      const data = {
        companyInformation: this.profile.company,
      };

      try {
        await this.$store.dispatch("profile/updateProfile", data);
      } catch (error) {
        this.errorAlertNotification(
          "Error al actualizar el perfil del usuario."
        );
      } finally {
        this.loadingDismiss();
      }
    },
  },
});
</script>

<style scoped>
</style>