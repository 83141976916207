import CampaignStatsService from "../../services/CampaignStatsService";

const state = () => ({
  campaign: {},
  channel: {},
  tags: {},
  template: {},
  landing: {},
  senders: {},
  senderTotales: {},
});

const getters = {};

const actions = {
  async getSms({ commit }, data) {
    try {
      const response = await CampaignStatsService.getSms(data);
      commit("setSms", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getMailing({ commit }, data) {
    try {
      const response = await CampaignStatsService.getMailing(data);
      commit("setMailing", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getVoice({ commit }, data) {
    try {
      const response = await CampaignStatsService.getVoice(data);
      commit("setVoice", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSmsDataTable({ commit }, data) {
    try {
      const response = await CampaignStatsService.getSmsDataTable(data);
      commit("setSmsDataTable", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getMailingDataTable({ commit }, data) {
    try {
      const response = await CampaignStatsService.getMailingDataTable(data);
      commit("setMailingDataTable", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getVoiceDataTable({ commit }, data) {
    try {
      const response = await CampaignStatsService.getVoiceDataTable(data);
      commit("setVoiceDataTable", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setSms(state, response) {
    state.campaign = response.campaign;
    state.channel = response.channel;
    state.tags = response.tags;
  },
  setMailing(state, response) {
    state.campaign = response.campaign;
    state.template = JSON.parse(response.template);
    state.landing = response.landing;
  },
  setVoice(state, response) {
    state.campaign = response.campaign;
    state.channel = response.channel;
    state.tags = response.tags;
  },
  setSmsDataTable(state, response) {
    state.senders = response.data;
    state.senderTotales = response.additional;
  },
  setMailingDataTable(state, response) {
    state.senders = response.data;
    state.senderTotales = response.additional;
  },
  setVoiceDataTable(state, response) {
    state.senders = response.data;
    state.senderTotales = response.additional;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
