<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title> Estadística de la campaña</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="bg-default">
      <ion-card>
        <ion-card-content>
          <ion-label>
            <h3>
              <b>{{ $t('Campaña') }}:</b> {{ campaignStats.campaign.campaignName }}</h3>
            <p><b>{{ $t('Estado') }}:</b> {{ campaignStats.campaign.status_name }}</p>
            <p>
              <b>{{ $t('Tipo de campaña') }}:</b> {{ campaignStats.campaign.typeName }}
            </p>
            <p>
              <b>{{ $t('Impactados') }}:</b> {{ campaignStats.campaign.delivered_total }}
            </p>
            <p>
              <b>{{ $t('No impactados') }}:</b>
              {{ campaignStats.campaign.undelivered_total }}
            </p>
            <p>
              <b>{{ $t('Impactados totales') }}:</b>
              {{ campaignStats.campaign.sent_total }}
            </p>
            <p>
              <b>{{ $t('Lanzamiento') }}:</b> 
              {{ dateFormat(campaignStats.campaign.created_at) }}
            </p>
            <p>
              <b>{{ $t('Finalización') }}:</b> 
              <span v-if="campaignStats.campaign && campaignStats.campaign.finished_at">{{ dateFormat(campaignStats.campaign.finished_at) }}</span>
            </p>
          </ion-label>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-content>
          <h3><b>{{ $t('Datos del envió') }}</b></h3>
          <ion-label
            v-for="(sender, index) in campaignStats.senders"
            :key="index"
          >
            <p><b>{{ $t('ID envió') }}:</b> {{ sender.sending_id }}</p>
            <p><b>{{ $t('Fecha') }}:</b>  {{ dateFormat(sender.sending_creation_date) }}</p>
            <p><b>{{ $t('Enviados') }}:</b> {{ sender.sent_total }}</p>
            <p><b>{{ $t('Entregados') }}:</b> {{ sender.delivered_total }}</p>
            <p><b>{{ $t('Entregados únicos') }}:</b> {{ sender.delivered_unique }}</p>
            <p><b>{{ $t('No entregados') }}:</b> {{ sender.undelivered_total }}</p>
            <p><b>{{ $t('Pendiente') }}:</b> {{ sender.pending }}</p>
            <p><b>{{ $t('Clics') }}:</b> {{ sender.clicked_total }}</p>
            <p><b>{{ $t('Bajas') }}:</b> {{ sender.unsubscribed_unique }}</p>
            <p><b>{{ $t('Coste') }}:</b> {{ sender.cost }} {{ currencySymbol() }}</p>
          </ion-label>
          <br />
          <ion-label
            v-for="(senderTotales, index) in campaignStats.senderTotales"
            :key="index"
          >
            <p><b>{{ $t('Total enviados') }}:</b> {{ senderTotales.sent_total }}</p>
            <p><b>{{ $t('Total entregados') }}:</b> {{ senderTotales.delivered_total }}</p>
            <p>
              <b>{{ $t('Total entregados únicos') }}:</b>
              {{ senderTotales.delivered_unique }}
            </p>
            <p>
              <b>{{ $t('Total no entregados') }}:</b> {{ senderTotales.undelivered_total }}
            </p>
            <p>
              <b>{{ $t('Total pendiente') }}:</b> {{ calculatePending(senderTotales) }}
            </p>
            <p><b>{{ $t('Total clics') }}:</b> {{ senderTotales.clicked_total }}</p>
            <p><b>{{ $t('Total bajas') }}:</b> {{ senderTotales.unsubscribed_unique }}</p>
            <p>
              <b>{{ $t('Total coste') }}:</b> {{ senderTotales.cost }}
              {{ currencySymbol() }}
            </p>
          </ion-label>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
} from "@ionic/vue";

export default defineComponent({
  name: "MailingCampaignStatsPage",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
  },
  computed: {
    campaignStats() {
      return this.$store.state.campaignStats;
    },
  },
  async mounted() {
    this.getMailingCampaignStats();
    this.getMailingDataTableCampaignStats();
  },
  methods: {
    async getMailingCampaignStats() {
      const campaignId = this.$route.params.campaignId;
      try {
        await this.$store.dispatch("campaignStats/getMailing", campaignId);
      } catch (error) {
        this.errorAlertNotification(error.response.data.message);
      }
    },
    async getMailingDataTableCampaignStats() {
      const data = {
        page: 1,
        perPage: 5,
        campaignId: this.$route.params.campaignId,
      };

      try {
        await this.$store.dispatch("campaignStats/getMailingDataTable", data);
      } catch (error) {
        this.errorAlertNotification(error.response.data.message);
      }
    },
    calculatePending(data) {
      let pending =
        parseInt(data.sent_total) -
        parseInt(data.delivered_total) -
        parseInt(data.undelivered_total);
      if (pending < 0) {
        pending = 0;
      }
      return pending;
    },
  },
});
</script>

<style scoped>
</style>