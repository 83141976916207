<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <ion-button @click="openMenu()">
          <ion-icon :icon="menuOutline" size="large"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ pageTitle }}</ion-title>
      <ion-buttons slot="end" v-if="showFilters">
        <ion-button @click="openFilters()">
          <ion-icon :icon="filterOutline" size="large"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  menuController,
} from "@ionic/vue";
import { menuOutline, filterOutline } from "ionicons/icons";

export default defineComponent({
  name: "AppHeader",
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
  },
  props: {
    pageTitle: {
      require: true,
      type: String,
    },
    showFilters: {
      require: false,
      type: Boolean,
    },
  },
  data() {
    return {
      menuOutline,
      filterOutline
    };
  },
  methods: {
    openFilters() {
      this.emitter.emit('showFilters')
    },
    openMenu() {
      menuController.open("app-menu");
    },
    
  },
});
</script>

<style scoped>
</style>
