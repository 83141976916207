<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>{{ $t('Añadir saldo') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="bg-default">
      <ion-card>
        <ion-card-content>
          <ion-items>
            <ion-item>
              <p>{{ $t('Por favor, revisa los detalles de esta compra') }}.</p>
            </ion-item>
            <ion-item>
              <ion-label>
                <p><b>{{ $t('Descripción') }}:</b> {{ payment.invoiceTmp.description }}</p>
                <p><b>{{ $t('Cantidad') }}:</b> 1</p>
                <p>
                  <b>{{ $t('Precio') }}:</b>
                  {{ currencyFormat(payment.invoiceTmp.amount) }}
                  {{ currencySymbol(payment.currency.code) }}
                </p>
                <p>
                  <b>{{ $t('Subtotal') }}:</b>
                  {{ currencyFormat(subtotal) }}
                  {{ currencySymbol(payment.currency.code) }}
                </p>
                <p>
                  <b> IVA:</b>
                  {{ payment.invoiceTmp.pc_iva }}%
                  {{ currencyFormat(payment.invoiceTmp.iva) }}
                  {{ currencySymbol(payment.currency.code) }}
                </p>
                <p
                  v-if="
                    payment.invoiceTmp.pc_tasa > 0 &&
                    payment.invoiceTmp.pc_tasa_fija > 0
                  "
                >
                  <b> {{ payment.invoiceTmp.pc_iva }}% IVA:</b>
                  <span
                    v-if="
                      payment.invoiceTmp.pc_tasa > 0 &&
                      payment.invoiceTmp.pc_tasa_fija > 0
                    "
                  >
                    {{ payment.invoiceTmp.pc_tasa }}% +
                    {{ currencyFormat(payment.invoiceTmp.pc_tasa_fija) }}
                    {{ currencySymbol(payment.currency.code) }}
                  </span>
                  <span v-else-if="payment.invoiceTmp.pc_tasa > 0">
                    {{ payment.invoiceTmp.pc_tasa }}%
                  </span>
                  <span v-if="payment.invoiceTmp.pc_tasa_fija > 0">
                    {{ currencyFormat(payment.invoiceTmp.pc_tasa_fija) }}
                    {{ currencySymbol(payment.currency.code) }}
                  </span>
                </p>
                <p>
                  <b>{{ $t('Total') }}:</b>
                  {{ currencyFormat(payment.invoiceTmp.total) }}
                  {{ currencySymbol(payment.currency.code) }}
                </p>
              </ion-label>
            </ion-item>
          </ion-items>
          <ion-button
            color="success"
            type="button"
            class="btn-payment"
            expand="block"
            @click="confirm()"
            >{{ $t('Confirmar') }}</ion-button
          >
        </ion-card-content>
      </ion-card>

      <payment-add-credit-card-info />
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import {
  IonPage,
  IonContent,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonTitle,
  IonCard,
  IonCardContent,
  IonItem,
  IonButton,
} from "@ionic/vue";
import PaymentAddCreditCardInfo from "./PaymentAddCreditCardInfo.vue";

export default defineComponent({
  name: "PaymentAddCreditCardConfirm",
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonBackButton,
    IonTitle,
    IonCard,
    IonCardContent,
    IonItem,
    IonButton,
    PaymentAddCreditCardInfo,
  },
  computed: {
    payment() {
      return this.$store.state.payment;
    },
    subtotal() {
      return this.payment.invoiceTmp.amount * 1;
    },
  },
  methods: {
    async confirm() {
      this.loadingPresenter();

      setTimeout(function (scope) {
        this.loadingDismiss();
        window.location.href = scope.payment.urlCreditCardPay
      }, 1000, this)
    },
  },
});
</script>

<style scoped>
.btn-payment {
  margin: 10px 0px 10px 15px;
}
</style>