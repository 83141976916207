import axios from "axios";
import moment from "moment";
import BrowserCache from "./BrowserCache";
import getEnv from '@/util/env';

const API_URL = getEnv('VUE_APP_API_URL') + "/api";
const TWO_WAY_REMEMBER_TOKEN = "twoWayRememberToken";

class AuthService {
  async login(auth) {
    const response = await axios.post(API_URL + "/login", {
      username: auth.username,
      password: auth.password,
      two_way_remember_360:
        localStorage.getItem(TWO_WAY_REMEMBER_TOKEN) ?? null,
    });

    return response.data;
  }

  async twoWayCheck(auth) {
    const response = await axios.post(API_URL + "/two-way-check", {
      username: auth.username,
      rememberme: auth.rememberme,
      logged_token: auth.loggedToken,
      two_way_code: auth.twoWayCode,
    });

    return response.data;
  }

  async refresh() {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await axios.get(API_URL + "/refresh", {
        headers: { Authorization: "Bearer " + user.token },
      });

      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  }

  async logout() {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(API_URL + "/logout", {
      headers: { Authorization: "Bearer " + user.token },
    });
    this.clearLocalStorageItem();
    return response.data;
  }

  async removeUserIfExpired() {
    const validateRefreshToken = await this.validateRefreshToken();

    if (!validateRefreshToken) {
      this.clearLocalStorageItem();
    }

    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return;
    }
    const response = await axios.get(API_URL + "/user", {
      headers: { Authorization: "Bearer " + user.token },
    });

    if (
      response.data.status === "Token is Expired" ||
      response.data.status === "Token is Invalid"
    ) {
      this.clearLocalStorageItem();
    }
  }

  authHeader() {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.token) {
      return { Authorization: "Bearer " + user.token };
    } else {
      return {};
    }
  }

  async validateRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      return false;
    }

    const localDate = moment.utc(user.tokenExpire).toDate();
    const dateTokenExpire = moment(localDate)
      .local()
      .format("YYYY-MM-DD kk:mm:ss");
    const date = moment().format("YYYY-MM-DD kk:mm:ss");

    if (date > dateTokenExpire) {
      const refresh = await this.refresh();

      if (!refresh) {
        return false;
      }
    }

    return true;
  }

  async clearLocalStorageItem() {
    const rememberToken = localStorage.getItem(TWO_WAY_REMEMBER_TOKEN);
    await BrowserCache.clear();

    localStorage.setItem(TWO_WAY_REMEMBER_TOKEN, rememberToken);
  }
}

export default new AuthService();
