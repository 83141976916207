export default function ({ to, next }) {
  const publicPages = ["/login", "/register"];
  const loginRequired = publicPages.includes(to.path);
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    next({ name: "Login" });
  } else if (loginRequired && loggedIn) {
    next({ name: "Home" });
  }

  return next();
}
