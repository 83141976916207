import SupportService from "../../services/SupportService";

const state = () => ({
  subject: "",
  message: "",
});

const getters = {};

const actions = {
  async sendMessage({ commit }, data) {
    try {
      const response = await SupportService.sendMessage(data);
      commit("setSendMessage", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setSendMessage(state) {
    state.subject = "";
    state.message = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
