<template>
  <ion-page>
    <app-header page-title="Estadísticas SMS" :show-filters="true" />
    <ion-content class="bg-default">
      <filter-list
        :init-date="campaignStatsSmsList.initDate"
        :end-date="campaignStatsSmsList.endDate"
        @filterApply="filterApply"
      />
      <div v-if="campaignStatsSmsList.all.length > 0">
        <ion-card
          v-for="(item, index) in campaignStatsSmsList.all"
          :key="index"
        >
          <ion-card-header>
            <ion-card-subtitle
              >{{ item.channel_type_name }}
              <span class="ion-float-right"
                ># {{ item.campaign_id }}</span
              ></ion-card-subtitle
            >
            <ion-card-title style="font-size: 1.4em">{{
              item.campaign_name
            }}</ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <ion-row class="ion-align-items-center">
              <ion-col size="5">
                <p><b>{{ $t('Enviados')}}:</b> {{ item.sent_total }}</p>
                <p><b>{{ $t('Entregados')}}:</b> {{ item.delivered_total }}</p>
                <p><b>{{ $t('E. únicos')}}:</b> {{ item.delivered_unique }}</p>
                <p><b>{{ $t('No entregados')}}:</b> {{ item.undelivered_total }}</p>
                <p><b>{{ $t('Pendiente')}}:</b> {{ item.pending }}</p>
              </ion-col>
              <ion-col>
                <p class="ion-text-right">
                  <b> {{ $t('Clics') }}:</b> {{ item.clicked_total }}
                </p>
                <p class="ion-text-right">
                  <b> {{ $t('Bajas') }}:</b> {{ item.unsubscribed_unique }}
                </p>
                <p class="ion-text-right">
                  <b> {{ $t('Coste') }}:</b> {{ item.cost }} {{ currencySymbol() }}
                </p>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
              <ion-col>
                <ion-icon
                  :icon="calendarOutline"
                  class="icon-margin"
                ></ion-icon>
                {{ dateFormat(item.campaign_date) }}
              </ion-col>
              <ion-col>
                <ion-button
                  fill="outline"
                  size="small"
                  class="ion-float-right"
                  @click="viewStats(item)"
                  ><ion-icon
                    :icon="pieChartOutline"
                    class="icon-margin"
                  ></ion-icon>
                  Ver</ion-button
                >
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>

        <ion-infinite-scroll
          @ionInfinite="loadData($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="isDisabled"
        >
          <ion-infinite-scroll-content
            loading-spinner="bubbles"
            loading-text="Cargando datos..."
          >
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
      <div v-else>
        <ion-list>
          <ion-item>
            {{ $t('No hay estadisticas para el rango de fechas') }}
          </ion-item>
          <ion-item>
            <ion-label>
              <h3>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </h3>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonCard,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSkeletonText,
  IonIcon,
} from "@ionic/vue";
import { pieChartOutline, calendarOutline } from "ionicons/icons";
import AppHeader from "../../../../components/Header.vue";
import FilterList from "../../../../components/FilterList.vue";

export default defineComponent({
  name: "SmsCampaignStatsListPage",
  components: {
    IonPage,
    IonContent,
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSkeletonText,
    IonIcon,
    AppHeader,
    FilterList,
  },
  data() {
    return {
      pieChartOutline,
      calendarOutline,
      disabled: true,
      emptyData: false,
    };
  },
  computed: {
    campaignStatsSmsList() {
      return this.$store.state.campaignStatsSmsList;
    },
  },
  async created() {
    this.getAllCampaignStatsSmsList();
  },
  methods: {
    async getAllCampaignStatsSmsList() {
      const data = {
        perPage: this.campaignStatsSmsList.perPage,
        initDate: this.campaignStatsSmsList.initDate,
        endDate: this.campaignStatsSmsList.endDate,
      };
      try {
        await this.$store.dispatch(
          "campaignStatsSmsList/getChannelDataTable",
          data
        );
        this.validateEmptyData();
      } catch (error) {
        this.errorAlertNotification("Error al listar estadísticas de SMS");
      }
    },
    async loadData(event) {
      const data = {
        perPage: this.campaignStatsSmsList.perPage,
        page: this.campaignStatsSmsList.currentPage + 1,
        initDate: this.campaignStatsSmsList.initDate,
        endDate: this.campaignStatsSmsList.endDate,
      };
      try {
        await this.$store.dispatch(
          "campaignStatsSmsList/getChannelDataTable",
          data
        );
        event.target.complete();
        this.validateEmptyData();
      } catch (error) {
        this.errorAlertNotification("Error al listar estadísticas de SMS");
      }
    },
    async filterApply(filterData) {
      const data = {
        perPage: this.campaignStatsSmsList.perPage,
        page: 1,
        initDate: filterData.initDate,
        endDate: filterData.endDate,
      };
      try {
        await this.$store.dispatch(
          "campaignStatsSmsList/getChannelDataTableFilter",
          data
        );
        this.validateEmptyData();
      } catch (error) {
        this.errorAlertNotification("Error al listar estadísticas de SMS");
      }
    },
    viewStats(item) {
      this.$router.push({
        name: "SmsCampaignStats",
        params: { campaignId: item.campaign_id },
      });
    },
    validateEmptyData() {
      this.campaignStatsSmsList.all.length === 0
        ? (this.emptyData = true)
        : (this.emptyData = false);
    },
  },
});
</script>

<style scoped>
.icon-margin {
  margin-right: 5px;
}
</style>