import axios from "axios";
import AuthService from "./AuthService";
import getEnv from '@/util/env';

const API_URL = getEnv('VUE_APP_API_URL') + "/api";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

export default class Service {
  async get(path, params = {}) {
    const validateRefreshToken = await AuthService.validateRefreshToken();

    if (!validateRefreshToken) {
      AuthService.clearLocalStorageItem();
    }

    const response = await axios.get(API_URL + path, {
      headers: this.authHeader(),
      params: params,
      validateStatus: (status) => {
        return this.checkRequestError(status);
      },
    });

    await this.checkToken(response);

    return response.data;
  }

  async post(path, data) {
    const validateRefreshToken = await AuthService.validateRefreshToken();

    if (!validateRefreshToken) {
      AuthService.clearLocalStorageItem();
    }

    const response = await axios.post(API_URL + path, data, {
      headers: this.authHeader(),
      validateStatus: (status) => {
        return this.checkRequestError(status);
      },
    });

    await this.checkToken(response);

    return response.data;
  }

  async put(path, data) {
    const validateRefreshToken = await AuthService.validateRefreshToken();

    if (!validateRefreshToken) {
      AuthService.clearLocalStorageItem();
    }

    const response = await axios.put(API_URL + path, data, {
      headers: this.authHeader(),
      validateStatus: (status) => {
        return this.checkRequestError(status);
      },
    });

    await this.checkToken(response);

    return response.data;
  }

  async delete(path) {
    const validateRefreshToken = await AuthService.validateRefreshToken();

    if (!validateRefreshToken) {
      AuthService.clearLocalStorageItem();
    }

    const response = await axios.delete(API_URL + path, {
      headers: this.authHeader(),
      validateStatus: (status) => {
        return this.checkRequestError(status);
      },
    });

    await this.checkToken(response);

    return response.data;
  }

  async checkToken(response) {
    if (
      response.data.status === "Token is Expired" ||
      response.data.status === "Token is Invalid"
    ) {
      await AuthService.logout();
      throw { message: "Auth token expired" };
    }
  }

  authHeader() {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.token) {
      return { Authorization: "Bearer " + user.token };
    } else {
      return {};
    }
  }

  headers() {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.token) {
      return {
        Authorization: "Bearer " + user.token,
        "Content-Type": "multipart/form-data",
      };
    } else {
      return {};
    }
  }

  checkRequestError(status) {
    switch (status) {
      case 400:
        break;
      case 422:
        break;
    }
    return status >= 200 && status < 300;
  }
}
