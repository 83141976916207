import AccountService from "../../services/AccountService";

const state = () => ({
  amount: 0,
  currency: "EUR",
});

const getters = {};

const actions = {
  async getUserBalance({ commit }) {
    try {
      const response = await AccountService.getUserBalance();
      if (response.balance) {
        commit("setAmount", response.balance);
        commit(
          "setCurrencySymbol",
          JSON.parse(localStorage.getItem("user")).currencyCode
        );
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setAmount(state, amount) {
    state.amount = amount;
  },
  setCurrencySymbol(state, currency) {
    state.currency = currency;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
