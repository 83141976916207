<template>
  <ion-page>
    <app-header page-title="Home" />
    <ion-content class="bg-default">
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle> Añadir saldo </ion-card-subtitle>
          <ion-card-title style="font-size: 1.4em">
            {{ balance.amount }} {{ balance.currency }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-row class="ion-align-items-center">
            <ion-col>
              <ion-button
                fill="outline"
                size="small"
                class="ion-float-right"
                @click="goToPage('PaymentAdd')"
                ><ion-icon :icon="addOutline" class="icon-margin"></ion-icon>
                Cargar saldo</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Mis campañas</ion-card-subtitle>
          <ion-card-title style="font-size: 1.4em">
            {{ campaigns.total }} campañas registradas
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-row class="ion-align-items-center">
            <ion-col>
              <ion-button
                fill="outline"
                size="small"
                class="ion-float-right"
                @click="viewCampaigns"
                ><ion-icon :icon="eyeOutline" class="icon-margin"></ion-icon>
                Ver más</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle> Estadísticas de SMS</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <ion-row class="ion-align-items-center">
            <ion-col>
              <ion-button
                fill="outline"
                size="small"
                class="ion-float-right"
                @click="goToPage('SmsCampaignStatsList')"
                ><ion-icon :icon="eyeOutline" class="icon-margin"></ion-icon>
                Ver más</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle> Estadísticas de Mailing</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <ion-row class="ion-align-items-center">
            <ion-col>
              <ion-button
                fill="outline"
                size="small"
                class="ion-float-right"
                @click="goToPage('MailingCampaignStatsList')"
                ><ion-icon :icon="eyeOutline" class="icon-margin"></ion-icon>
                Ver más</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle> Estadísticas de Voz</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <ion-row class="ion-align-items-center">
            <ion-col>
              <ion-button
                fill="outline"
                size="small"
                class="ion-float-right"
                @click="goToPage('VoiceCampaignStatsList')"
                ><ion-icon :icon="eyeOutline" class="icon-margin"></ion-icon>
                Ver más</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import { addOutline, eyeOutline } from "ionicons/icons";
import AppHeader from "../../components/Header.vue";

export default defineComponent({
  name: "HomePage",
  components: {
    IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    AppHeader,
  },
  data() {
    return {
      addOutline,
      eyeOutline,
    };
  },
  computed: {
    balance() {
      return this.$store.state.balance;
    },
    campaigns() {
      return this.$store.state.campaigns;
    },
    campaignStatsSmsList() {
      return this.$store.state.campaignStatsSmsList;
    },
  },
  created() {
    this.getUserBalance();
    this.getAllCampaigns();
  },
  methods: {
    async getUserBalance() {
      try {
        await this.$store.dispatch("balance/getUserBalance");
      } catch (error) {
        this.errorAlertNotification(error.response.data.message);
      }
    },
    async getAllCampaigns() {
      const data = {
        perPage: this.campaigns.perPage,
      };
      try {
        await this.$store.dispatch("campaigns/getAll", data);
      } catch (error) {
        this.errorAlertNotification(error.response.data.message);
      }
    },
    goToPage(name) {
      this.$router.push({
        name: name,
      });
    },
  },
});
</script>

<style scoped>
.icon-margin {
  margin-right: 5px;
}
</style>
