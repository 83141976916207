<template>
  <ion-menu side="start" menu-id="app-menu" content-id="main-content">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>
          <a class="a-logo" button @click="menuNavigation('Home')">
            <img
                class="logo"
                :src="require('../../public/assets/logos/logo-white.svg')"
                alt="Logo"
              />
            </a>
          </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item button @click="menuNavigation('Campaigns')">
          <ion-label>{{ $t('Campañas') }} </ion-label>
        </ion-item>
        <ion-item button @click="menuNavigation('PaymentAdd')">
          {{ $t('Añadir saldo') }}
        </ion-item>
        <ion-item @click="toggleSubmenu('stats')">
          {{ $t('Estadísticas') }}
          <ion-icon
            :icon="
              subMenuStats === false ? chevronDownOutline : chevronUpOutline
            "
            size="small"
            slot="end"
          ></ion-icon>
        </ion-item>
        <ion-list class="ion-padding-start" v-if="subMenuStats">
          <ion-item @click="menuNavigationStats('SmsCampaignStatsList')"
            >SMS</ion-item
          >
          <ion-item @click="menuNavigationStats('MailingCampaignStatsList')"
            >Mailing</ion-item
          >
          <ion-item @click="menuNavigationStats('VoiceCampaignStatsList')"
            >Voz</ion-item
          >
        </ion-list>
        <ion-item @click="menuNavigation('Support')">{{ $t('Soporte') }}</ion-item>
        <ion-item @click="menuNavigation('Profile')">{{ $t('Mi perfil') }}</ion-item>
        <ion-item @click="logout()">{{ $t('Cerrar sesión') }}</ion-item>
        <ion-item>
          <ion-grid>
            <ion-row>
              <ion-col style="text-align: center">
                <span
                  v-for="language in languagesObj.languages"
                  :key="language.iso"
                >
                  <img
                    :id="'btn-language-' + language.iso"
                    :src="setIsoFlag(language.iso)"
                    style="margin-left: 2px; margin-right: 2px;"
                    class="flag"
                    @click="changeLanguage(language.iso)"
                  >
                </span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonMenu,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonItem,
  IonList,
  IonLabel,
  menuController,
} from "@ionic/vue";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import { Languages } from '../classes/Languages.js'

export default defineComponent({
  name: "AppMenu",
  components: {
    IonMenu,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,
    IonList,
    IonLabel,
  },
  props: {
    pageTitle: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      chevronDownOutline,
      chevronUpOutline,
      subMenuStats: false,
      languagesObj: new Languages(),
    }
  },
  methods: {
    setIsoFlag (isoCode) {
      const lang = isoCode === 'en' ? 'gb' : isoCode
      return require('../../public/assets/flags/' + lang + '.svg')
    },
    async changeLanguage (langIsoCode) {
      this.$i18n.locale = langIsoCode
      localStorage.setItem('language', langIsoCode)
      const params = {
        locale: langIsoCode,
      }
      try {
        await this.$store.dispatch("profile/updateLanguage", params);
      } catch (error) {
        this.errorAlertNotification(
          "Error al actualizar el lenguage perfil del usuario."
        );
      }

      try {
        await this.$store.dispatch("profile/getProfile");
      } catch (error) {
        this.errorAlertNotification(
          "Error al actualizar el perfil del usuario."
        );
      }
    },

    menuNavigation(routeName) {
      menuController.close("app-menu");
      this.toggleSubmenu("stats");
      this.$router.push({ name: routeName });
    },
    menuNavigationStats(routeName) {
      menuController.close("app-menu");
      this.$router.push({ name: routeName });
    },
    toggleSubmenu(name) {
      if (name === "stats") {
        this.subMenuStats = !this.subMenuStats;
      }
    },
    async logout() {
      this.loadingPresenter();
      try {
        await this.$store.dispatch("auth/logout");
        menuController.close("app-menu");
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.errorAlertNotification("Error al cerrar sesión");
      } finally {
        this.loadingDismiss();
      }
    },
  },
});
</script>

<style scoped>
.a-logo {
  display: block;
  text-align: left;
}
.logo {
  width: 100%;
  max-width: 160px;
}
.flag {
  width: 25px;
  height: 25px;
}
</style>
