import getSymbolFromCurrency from "currency-symbol-map";

export default {
  install: (app) => {
    app.config.globalProperties.currencyFormat = (
      amount,
      decimalCount = 2,
      decimal = ",",
      thousands = "."
    ) => {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    };
    app.config.globalProperties.currencySymbol = (code = false) => {
      if (!code) {
        code = JSON.parse(localStorage.getItem("user")).currencyCode ?? "EUR";
      }

      if (code === "COP") {
        return getSymbolFromCurrency(code) + " " + code;
      }

      return getSymbolFromCurrency(code);
    };
    app.config.globalProperties.parseAmount = (amount) => {
      return amount.replace(",", ".");
    };
  },
};
