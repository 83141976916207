<template>
  <ion-page>
    <app-header page-title="Añadir saldo" />
    <ion-content class="bg-default">
      <ion-card>
        <ion-card-content>
          <div v-if="!payment.billingProfiles">
            <p>
              {{ $t('Para poder realizar la compra debe introducir los datos del perfil fiscal') }}
            </p>
          </div>
          <div v-else>
            <ion-accordion-group :value="payment.paymentMethod.bankTransfer">
              <ion-accordion
                :value="payment.paymentMethod.bankTransfer"
                v-if="payment.activeTransfer"
              >
                <ion-item slot="header">
                  <font-awesome-icon class="blue-color icon-sm ion-padding-end " icon="fas fa-university" /> 
                  <ion-label> 
                    Transferencia bancaria
                  </ion-label>
                </ion-item>
                <div slot="content">
                  <ion-list>
                    <ion-item>
                      <p>{{ $t('El método de pago idóneo para cantidades altas') }}.</p>
                    </ion-item>
                    <ion-item>
                      <p>
                        {{ $t('Crear una factura proforma a pagar por transferencia bancaria en 7 días') }}.
                      </p>
                    </ion-item>
                    <ion-item>
                      <p>
                        {{ $t('El saldo será agregado dentro de las 24 horas siguientes a la recepción del pago') }}.
                      </p>
                    </ion-item>
                    <ion-item>
                      <p>
                        {{ $t('El importe mínimo es de') }}
                        {{ currencyFormat(payment.paymentsInfo.transfer.min) }}
                        {{ currencySymbol(payment.currency.code) }}.
                      </p>
                    </ion-item>
                    <ion-item>
                      <p>{{ $t('Sin cargo') }}.</p>
                    </ion-item>
                  </ion-list>
                  <ion-button
                    color="success"
                    type="button"
                    class="btn-payment"
                    expand="block"
                    @click="
                      selectPaymentMethod(payment.paymentMethod.bankTransfer)
                    "
                    >{{ $t('Cargar saldo') }}</ion-button
                  >
                </div>
              </ion-accordion>
              <ion-accordion
                :value="payment.paymentMethod.creditCard"
                v-if="payment.activeCreditCard"
              >
                <ion-item slot="header">
                  <font-awesome-icon class="blue-color icon-s ion-padding-end" icon="fas fa-credit-card" /> 
                  <ion-label>
                    Tarjeta de crédito
                  </ion-label>
                </ion-item>
                <div slot="content">
                  <ion-list>
                    <ion-item>
                      <p>{{ $t('Pago único') }}.</p>
                    </ion-item>
                    <ion-item>
                      <p>{{ $t('Carga de saldo inmediata') }}.</p>
                    </ion-item>
                    <ion-item>
                      <p>{{ $t('Pago seguro') }}.</p>
                    </ion-item>
                    <ion-item>
                      <p>{{ $t('Aceptamos Visa, Mastercard y American Express') }}.</p>
                    </ion-item>
                    <ion-item>
                      <p>
                        {{ $t('El importe mínimo es de') }}
                        {{
                          currencyFormat(payment.paymentsInfo.creditCard.min)
                        }}
                        {{ currencySymbol(payment.currency.code) }}. {{ $t('El importe') }}
                        {{ $t('máximo es de') }}
                        {{
                          currencyFormat(payment.paymentsInfo.creditCard.max)
                        }}
                        {{ currencySymbol(payment.currency.code) }}.
                      </p>
                    </ion-item>
                    <ion-item>
                      <div
                        v-if="
                          payment.paymentsInfo.creditCard.commission > 0 &&
                          payment.paymentsInfo.creditCard.fixed_commission > 0
                        "
                      >
                        <p>
                          {{ $t('Se aplica una tasa de tramitación de') }}
                          {{ payment.paymentsInfo.creditCard.commission }}% +
                          {{
                            currencyFormat(
                              payment.paymentsInfo.creditCard.fixed_commission
                            )
                          }}
                          {{ currencySymbol(payment.currency.code) }}.
                        </p>
                      </div>
                      <div
                        v-else-if="
                          payment.paymentsInfo.creditCard.commission > 0
                        "
                      >
                        <p>
                          {{ $t('Se aplica una tasa de tramitación de') }}
                          {{ payment.paymentsInfo.creditCard.commission }}%.
                        </p>
                      </div>
                      <div
                        v-else-if="
                          payment.paymentsInfo.creditCard.fixed_commission > 0
                        "
                      >
                        <p>
                          {{ $t('Se aplica una tasa de tramitación de') }}
                          {{
                            currencyFormat(
                              payment.paymentsInfo.creditCard.fixed_commission
                            )
                          }}
                          {{ currencySymbol(payment.currency.code) }}.
                        </p>
                      </div>
                      <div v-else>
                        <p>{{ $t('Sin cargos') }}.</p>
                      </div>
                    </ion-item>
                  </ion-list>
                  <ion-button
                    color="success"
                    type="button"
                    class="btn-payment"
                    expand="block"
                    @click="
                      selectPaymentMethod(payment.paymentMethod.creditCard)
                    "
                    >{{ $t('Cargar saldo') }}</ion-button
                  >
                </div>
              </ion-accordion>
            </ion-accordion-group>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonAccordion,
  IonAccordionGroup,
  IonList,
  IonItem,
  IonButton,
  IonLabel,
} from "@ionic/vue";
import AppHeader from "../../components/Header.vue";

export default defineComponent({
  name: "PaymentAddPage",
  components: {
    IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    IonAccordion,
    IonAccordionGroup,
    IonList,
    IonItem,
    IonButton,
    IonLabel,
    AppHeader,
  },
  computed: {
    payment() {
      return this.$store.state.payment;
    },
  },
  async mounted() {
    this.getPaymentMethod();
  },
  methods: {
    async getPaymentMethod() {
      try {
        await this.$store.dispatch("payment/getPaymentMethod");
      } catch (error) {
        this.errorAlertNotification("Error al obtener los métodos de pagos");
      }
    },
    selectPaymentMethod(paymentMethodId) {
      if (this.payment.paymentMethod.bankTransfer === paymentMethodId) {
        this.getBankPaymentMethod();
      }
      if (this.payment.paymentMethod.creditCard === paymentMethodId) {
        this.getCreditCardPaymentMethod();
      }
    },
    async getBankPaymentMethod() {
      this.loadingPresenter();

      try {
        await this.$store.dispatch("payment/getBankPaymentMethod");
        this.$router.push({ name: "PaymentAddBankTransferSelected" });
      } catch (error) {
        this.errorAlertNotification(
          error.response.data.message || "Error al obtener los datos de transferencia bancaria"
        );
      } finally {
        this.loadingDismiss();
      }
    },
    async getCreditCardPaymentMethod() {
      this.loadingPresenter();

      try {
        await this.$store.dispatch("payment/getCreditCardPaymentMethod");
        this.$router.push({ name: "PaymentAddCreditCardSelected" });
      } catch (error) {
        this.errorAlertNotification(
          error.response.data.message || "Error al obtener los datos del pago por tarjeta de crédito"
        );
      } finally {
        this.loadingDismiss();
      }
    },
  },
});
</script>

<style scoped>
.btn-payment {
  margin: 10px 0px 10px 15px;
}

</style>