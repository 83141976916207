<template>
  <ion-row>
    <ion-col>
      <p class="ion-text-center">
        {{ $t('Ingresa tus datos para acceder a la plataforma') }}
      </p>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-item>
        <ion-label position="stacked">{{ $t('Usuario') }}:</ion-label>
        <ion-input
          name="username"
          type="text"
          :value="auth.username"
          @ionInput="auth.username = $event.target.value"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-item>
        <ion-label position="stacked">{{ $t('Contraseña') }}</ion-label>
        <ion-input
          name="password"
          type="password"
          :value="auth.password"
          @ionInput="auth.password = $event.target.value"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-button
    name="btnLogin"
    type="button"
    class="ion-margin-top ion-margin-bottom"
    expand="block"
    @click="login"
    >Acceder</ion-button
  >
</template>

<script>
import { defineComponent } from "vue";
import {
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
} from "@ionic/vue";
export default defineComponent({
  name: "LoginForm",
  components: {
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },
  methods: {
    async login() {
      this.loadingPresenter();
      const data = {
        username: this.auth.username,
        password: this.auth.password,
      };

      try {
        await this.$store.dispatch("auth/login", data);

        if (this.auth.token) {
          const params = {
            locale: localStorage.getItem('language'),
          }
          await this.$store.dispatch("profile/updateLanguage", params);
          await this.$store.dispatch("auth/getSectionsBlockeds", data);
          this.$router.push({ name: "Home" });
        }
      } catch (error) {
        this.errorAlertNotification(error.response.data.message);
      } finally {
        this.loadingDismiss();
      }
    },
  },
});
</script>

<style scoped>
</style>