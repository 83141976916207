<template>
  <ion-app>
    <app-menu />
    <ion-router-outlet id="main-content" />
  </ion-app>
</template>

<script>
import { defineComponent } from "vue";
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import AppMenu from "./components/Menu.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    AppMenu,
  },
});
</script>

<style>
</style>