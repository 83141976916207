import Service from "./Service";

const PATH = "/support";

class SupportService extends Service {
  sendMessage(params = {}) {
    return this.post(PATH + "/send-message", params);
  }
}

export default new SupportService();
