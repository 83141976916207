<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>{{ $t('Añadir saldo') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="bg-default">
      <ion-card>
        <ion-card-content>
          <ion-row>
            <ion-col>
              <p class="ion-text-center">
                {{ $t('Tu factura proforma se ha generado correctamente') }}.
              </p>
              <p class="ion-text-center">
                {{ $t('Serie / Nº Factura proforma') }}:
                <ion-text color="primary">
                  {{ payment.invoice.facturaId }}
                </ion-text>
              </p>
              <p class="ion-text-center">
                {{ $t('Importe') }}:
                <ion-text color="primary">
                  {{ currencyFormat(payment.invoiceTmp.total) }}
                  {{ currencySymbol(payment.currency.code) }}
                </ion-text>
              </p>
              <div class="ion-margin-top">
                <p>
                  {{ $t('Desde ahora y durante un plazo de 7 días puedes realizar la transferencia por un importe de') }}
                  <ion-text color="primary">
                    {{ currencyFormat(payment.invoiceTmp.total) }}
                    {{ currencySymbol(payment.currency.code) }} </ion-text
                  >{{ $t('y en concepto') }}
                  <ion-text color="primary">
                    {{ payment.invoice.facturaId }}
                  </ion-text>
                  {{ $t('en una de las siguientes cuentas bancarias') }}.
                </p>
              </div>
              <div
                class="ion-margin-top bg-info-bank"
                v-if="payment.infoBillingIsoCode === 'ES'"
              >
                <b>Banco</b> <span>Bankinter S.A.</span><br />
                <b>Dirección</b> <span>Avda. del Cedre, 1</span><br />
                <b>Localidad</b> <span>Vila-real</span><br />
                <b>Código postal</b>
                <span>12540, Castellón, España</span><br />
                <b>Cuenta Bancaria:</b>
                <span>0128 7630 8401 0000 1664</span><br />
                <b>IBAN:</b> <span>ES70 0128 7630 8401 0000 1664</span><br />
                <b>SWIF/BIC:</b> <span>BKBKESMM</span>
              </div>
              <div
                class="ion-margin-top bg-default"
                v-if="payment.infoBillingIsoCode === 'CO'"
              >
                <b>Banco:</b> Bancolombia SA <br />
                <b>Dirección:</b> Cra. 48 N 26-85 Av. Industriales
                <br />
                <b>Localidad:</b> Medellín-Colombia <br />
                <b>Cuenta de Ahorros:</b> 551-985945-71
              </div>
              <div
                class="ion-margin-top bg-default"
                v-if="payment.infoBillingIsoCode === 'BR'"
              >
                <b>Banco:</b> <span>Banco Santander</span> <br />
                <b>Agência:</b> <span>4256</span> <br />
                <b>Conta:</b> <span>130018367</span>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="ion-margin-top">
            <ion-col>
              <ion-button
                class="ion-float-right"
                color="light"
                size="small"
                @click="downloadInvoice()"
              >
              {{ $t('Descargar factura') }}
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
      <div class="btn-payment">
        <ion-button
          color="primary"
          size="small"
          fill="outline"
          @click="goTOPayment()"
        >
          Regresar
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonText,
} from "@ionic/vue";
import getEnv from "@/util/env";

export default defineComponent({
  name: "PaymentAddCreditCardSuccess",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonCard,
    IonCardContent,
    IonText,
  },
  computed: {
    payment() {
      return this.$store.state.payment;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    downloadInvoice() {
      window.location.href =
        getEnv("VUE_APP_API_URL") +
        "/api/billing/invoice/" +
        this.payment.invoice.id +
        "/pdf?token=" +
        this.user.token +
        "&download=1&locale=" +
        this.user.lang;
    },
    goTOPayment() {
      this.$router.push({ name: "PaymentAdd" });
    },
  },
});
</script>

<style scoped>
.btn-payment {
  margin: 20px 10px;
}

.bg-info-bank {
  background-color: #f6f7ff;
  padding: 20px;
}
</style>
