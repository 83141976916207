<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>{{ $t("Añadir saldo") }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="bg-default">
      <ion-card>
        <ion-card-content>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">{{ $t("Importe") }}:</ion-label>
                <ion-input
                  name="amount"
                  type="number"
                  :value="payment.amount"
                  @ionInput="payment.amount = $event.target.value"
                ></ion-input>
              </ion-item>
              <ion-label class="ion-padding">
                {{ $t("Importe mínimo") }}
                {{ currencyFormat(payment.paymentDetail.minAmountLocal) }}
                {{ currencySymbol(payment.currency.code) }} {{ $t("y máximo") }}
                {{ currencyFormat(payment.paymentDetail.maxAmountLocal) }}
                {{ currencySymbol(payment.currency.code) }}.
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-button
            color="success"
            type="button"
            class="ion-margin-top ion-margin-bottom"
            expand="block"
            @click="confirm()"
            >{{ $t("Continuar") }}</ion-button
          >

          <ion-list>
            <ion-radio-group
              :value="creditCardPayment"
              @ionInput="creditCardPayment = $event.target.value"
            >
              <ion-list-header>
                <ion-label>{{ $t("Selecciona la tarjeta") }}</ion-label>
              </ion-list-header>

              <ion-item>
                <ion-row>
                  <ion-col size="2">
                    <ion-radio value="0"></ion-radio>
                  </ion-col>
                  <ion-col size="10">
                    <p>{{ $t("Nueva tarjeta de crédito") }}</p>
                    <ion-row>
                      <ion-col size="2">
                        <ion-checkbox
                          @ionInput="isNewCreditCard = $event.target.value"
                          :value="isNewCreditCard"
                          checked
                        >
                        </ion-checkbox>
                      </ion-col>
                      <ion-col size="10">
                        <p>
                          {{
                            $t("Recordar tarjeta de crédito para futuros pagos")
                          }}
                        </p>
                      </ion-col>
                    </ion-row>
                  </ion-col>
                </ion-row>
              </ion-item>
              <ion-item>
                <p>{{ $t("Carga de saldo inmediata") }}.</p>
              </ion-item>
              <ion-item>
                <p>{{ $t("Pago seguro") }}.</p>
              </ion-item>
              <ion-item>
                <p>
                  {{ $t("Aceptamos Visa, Mastercard y American Express") }}.
                </p>
              </ion-item>
              <ion-item>
                <p>
                  {{ $t("El pago debe ser recibido dentro de los 7 días") }}.
                </p>
              </ion-item>
              <ion-item>
                <p>
                  {{ $t("El método de pago idóneo para cantidades altas") }}.
                </p>
              </ion-item>
              <ion-item>
                <p
                  v-if="
                    payment.paymentsInfo.creditCard.commission > 0 &&
                    payment.paymentsInfo.creditCard.fixed_commission > 0
                  "
                >
                  {{ $t("Se aplica una tasa de tramitación de") }}
                  {{ payment.paymentsInfo.creditCard.commission }}% +
                  {{
                    currencyFormat(
                      payment.paymentsInfo.creditCard.fixed_commission
                    )
                  }}
                  {{ currencySymbol(payment.currency.code) }}
                </p>
                <p v-else-if="payment.paymentsInfo.creditCard.commission > 0">
                  {{ $t("Se aplica una tasa de tramitación de") }}
                  {{ payment.paymentsInfo.creditCard.commission }}%
                </p>
                <p
                  v-else-if="
                    payment.paymentsInfo.creditCard.fixed_commission > 0
                  "
                >
                  {{ $t("Se aplica una tasa de tramitación de") }}
                  {{
                    currencyFormat(
                      payment.paymentsInfo.creditCard.fixed_commission
                    )
                  }}
                  {{ currencySymbol(payment.currency.code) }}
                </p>
                <p v-else>{{ $t("Sin cargo") }}.</p>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonTitle,
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonInput,
  IonButton,
} from "@ionic/vue";

export default defineComponent({
  name: "PaymentAddCreditCardSelected",
  components: {
    IonPage,
    IonContent,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonBackButton,
    IonTitle,
    IonCard,
    IonCardContent,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonInput,
    IonButton,
  },
  data() {
    return {
      creditCardPayment: "0",
      rememberCreditCard: true,
      isNewCreditCard: true,
    };
  },
  computed: {
    payment() {
      return this.$store.state.payment;
    },
  },
  methods: {
    async confirm() {
      this.loadingPresenter();

      const data = {
        amount: this.parseAmount(this.payment.amount),
        infobillingId:
          this.payment.paymentDetail.infoBilling.billingProfiles[0].id,
        subscribed_card: this.creditCardPayment,
        rememberCreditCard: this.rememberCreditCard,
      };

      try {
        await this.$store.dispatch(
          "payment/confirmCreditCardPaymentMethod",
          data
        );
        this.$router.push({ name: "PaymentAddCreditCardSuccess" });
      } catch (error) {
        const message = `${ error.response.data.message } ${error.response.data.minAmount || ''}`
        this.errorAlertNotification(message || "Error al obtener los datos del pago por tarjeta de crédito");
      } finally {
        this.loadingDismiss();
      }
    },
  },
});
</script>

<style scoped></style>
