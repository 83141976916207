import Service from "./Service";

const PATH = "/account";

class AccountService extends Service {
  getProfile() {
    return this.get(PATH + "/profile");
  }
  updateProfile(params = {}) {
    return this.post(PATH + "/update-profile", params);
  }
  getUserBalance() {
    return this.get(PATH + "/user/balance");
  }
  updateLanguage(params = {}) {
    return this.post(PATH + '/user/language', params)
  }
}

export default new AccountService();
