import AccountService from "../../services/AccountService";

const state = () => ({
  activeTimezone: "",
  company: "",
  countriesMccList: [],
  language: "",
  languages: [],
  user: {},
  timezoneOffsets: [],
  apiKey: "",
  image: "",
});

const getters = {};

const actions = {
  async getProfile({ commit }) {
    try {
      const response = await AccountService.getProfile();
      commit("setProfile", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateProfile({ commit }, data) {
    try {
      const response = await AccountService.updateProfile(data);
      commit("updateProfile", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateLanguage({ commit }, data) {
    try {
      const response = await AccountService.updateLanguage(data);
      commit("updateLanguage", response);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },

};

const mutations = {
  setProfile(state, response) {
    state.activeTimezone = response.activeTimezone;
    state.company = response.company;
    state.countriesMccList = response.countriesMccList;
    state.language = response.language;
    state.languages = response.languages;
    state.user = response.user;
    state.timezoneOffsets = response.timezoneOffsets;
    state.apiKey = response.apiKey;
  },
  updateProfile() {},
  updateLanguage() {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
