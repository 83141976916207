<template>
  <ion-page>
    <app-header page-title="Campañas" />
    <ion-content class="bg-default">
      <div v-if="campaigns.all.length > 0">
        <ion-card v-for="(item, index) in campaigns.all" :key="index">
          <ion-card-header>
            <ion-card-subtitle
              >{{ item.channelType }}
              <span class="ion-float-right"
                ># {{ item.sendingId }}</span
              ></ion-card-subtitle
            >
            <ion-card-title style="font-size: 1.4em">{{
              item.campaignName
            }}</ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <ion-row class="ion-align-items-center">
              <ion-col size="5">
                <b>{{ $t('Enviados') }}:</b> {{ item.total_processed }} /
                {{ item.total_recipients }}
              </ion-col>
              <ion-col>
                <span class="ion-float-right">
                  <ion-icon
                    :icon="calendarOutline"
                    class="icon-margin"
                  ></ion-icon>
                  {{ dateFormat(item.campaignUpdatedAt) }}
                </span>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
              <ion-col>
                <ion-badge color="success"> {{ item.statusName }} </ion-badge>
              </ion-col>
              <ion-col>
                <ion-button
                  fill="outline"
                  size="small"
                  class="ion-float-right"
                  :disabled="
                    campaigns.statuses.editable.includes(
                      parseInt(item.status_id)
                    )
                  "
                  @click="viewStats(item)"
                  ><ion-icon
                    :icon="pieChartOutline"
                    class="icon-margin"
                  ></ion-icon>
                  {{ $t('Ver') }}</ion-button
                >
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>

        <ion-infinite-scroll
          @ionInfinite="loadData($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="isDisabled"
        >
          <ion-infinite-scroll-content
            loading-spinner="bubbles"
            loading-text="Cargando datos..."
          >
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
      <div v-else>
        <ion-list>
          <ion-item>
            <ion-label>
              <h3>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </h3>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 100%"
                ></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonRow,
  IonCol,
  IonBadge,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSkeletonText,
  IonIcon,
} from "@ionic/vue";
import { pieChartOutline, calendarOutline } from "ionicons/icons";
import AppHeader from "../../components/Header.vue";

export default defineComponent({
  name: "CampaignListPage",
  components: {
    IonPage,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonRow,
    IonCol,
    IonBadge,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSkeletonText,
    IonIcon,
    AppHeader,
  },
  data() {
    return {
      pieChartOutline,
      calendarOutline,
      disabled: true,
    };
  },
  computed: {
    campaigns() {
      return this.$store.state.campaigns;
    },
    channelType() {
      return this.$store.state.channelType;
    },
  },
  async created() {
    this.getAllCampaigns();
  },
  methods: {
    async getAllCampaigns() {
      const data = {
        perPage: this.campaigns.perPage,
      };
      try {
        await this.$store.dispatch("campaigns/getAll", data);
      } catch (error) {
        this.errorAlertNotification(error.response.data.message);
      }
    },
    async loadData(event) {
      const data = {
        perPage: this.campaigns.perPage,
        page: this.campaigns.currentPage + 1,
      };
      try {
        await this.$store.dispatch("campaigns/getAll", data);
        event.target.complete();
      } catch (error) {
        this.errorAlertNotification(error.response.data.message);
      }
    },
    viewStats(item) {
      if (item.channelTypeId === this.channelType.SMS_ID) {
        this.$router.push({
          name: "SmsCampaignStats",
          params: { campaignId: item.campaignId },
        });
      }

      if (item.channelTypeId === this.channelType.MAILING_ID) {
        this.$router.push({
          name: "MailingCampaignStats",
          params: { campaignId: item.campaignId },
        });
      }

      if (item.channelTypeId === this.channelType.VOICE_INTERACTIVE_ID) {
        this.$router.push({
          name: "VoiceCampaignStats",
          params: { campaignId: item.campaignId },
        });
      }
    },
  },
});
</script>

<style scoped>
.icon-margin {
  margin-right: 5px;
}
</style>
